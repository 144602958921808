import React, { useState } from "react"

export interface RatingProps {
  className?: string;
  count?: number;
  value: number;
  color?: string;
  hoverColor?: string;
  activeColor?: string;
  size?: number;
  edit?: boolean;
  isHalf?: boolean;
  onChange?: (value: number) => void;
  emptyIcon?: React.ReactElement;
  halfIcon?: React.ReactElement;
  fullIcon?: React.ReactElement;
  emptyColor?: string
  fullColor?: string
  round?: boolean
}

interface IconProps {
  size?: number;
  color?: string;
}

const FullStar = ({ size = 24, color = "#000000" }: IconProps) => (
    <div style={{ color }}>
      <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
      </svg>
    </div>
  )

const HalfStar = ({ size = 24, color = "#000000" }: IconProps) => (
  <div style={{ color }}>
    <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
    </svg>
  </div>
)

const EmptyStar = ({ size = 24, color = "#959EAD" }: IconProps) => (
    <div style={{ color }}>
      <svg height={size} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
      </svg>
    </div>
  )

const Rating: React.FC<RatingProps> = ({
  className,
  count = 5,
  value,
  color = "#959EAD",
  hoverColor = "#ffc107",
  activeColor = "#ffc107",
  size = 30,
  edit = false,
  isHalf = true,
  onChange,
  fullColor,
  emptyColor,
  emptyIcon = <EmptyStar color={emptyColor}/>,
  halfIcon = <HalfStar color={fullColor}/>,
  fullIcon = <FullStar color={fullColor}/>,
  round,
}) => {
  const [hoverValue, setHoverValue] = useState<number | undefined>(undefined)

  const handleMouseMove = (index: number) => {
    if (!edit) {
      return
    }
    setHoverValue(index)
  }

  const handleMouseLeave = () => {
    if (!edit) {
      return
    }
    setHoverValue(undefined)
  }

  const handleClick = (index: number) => {
    if (!edit) {
      return
    }
    if (onChange) {
      onChange(index + 1)
    }
  }

  const getColor = (index: number) => {
    if (hoverValue !== undefined) {
      if (index <= hoverValue) {
        return hoverColor
      }
    }
    if (value > index) {
      return activeColor
    }
    return color
  }

  const stars = []

  for (let i = 0; i < count; i+=1) {
    let star: React.ReactElement
    if (isHalf && value - i > 0 && value - i < 1) {
      star = round ? fullIcon : halfIcon 
    } else if (i < value) {
      star = fullIcon
    } else {
      star = emptyIcon
    }

    if (hoverValue !== undefined) {
      if (i <= hoverValue) {
        star = fullIcon
      }
    }

    stars.push(
      <div
        key={i}
        role="button"
        tabIndex={i}
        className="cursor-pointer"
        onMouseMove={() => handleMouseMove(i)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(i)}
      >
        {React.cloneElement(star, {
          size,
          color: getColor(i),
        })}
      </div>,
    )
  }

  return <div className={`flex ${className}`}>{stars}</div>
}

export default Rating
