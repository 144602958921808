import Text from "lib/components/Text"
import Badge from "@components/shared-components/ui/Badge"
import Icon from "lib/components/Icons"

interface props {
  schedule: string
  label?: string
  iconSize?: number
  fontWeight?: "medium" | "semibold" | "bold"
  size?: "small" | "medium" | "extra-small" | "2extra-small"
  dataDelivery?: {
    offline_delivery: string
    online_delivery: string
  }
}
function CardSchedule({
  schedule,
  label,
  dataDelivery,
  iconSize = 20,
  size = "2extra-small",
  fontWeight,
}: props) {
  let weight = ""
  switch (fontWeight) {
    case "medium":
      weight = "font-medium"
      break
    case "semibold":
      weight = "font-semibold"
      break
    case "bold":
      weight = "font-bold"
      break
    default:
      weight = "font-medium"
      break
  }

  return (
    <div className="flex space-x-4 items-center">
      {dataDelivery?.offline_delivery && (
        <Badge weight={weight} label="offline class" customClass="text-blue-700 bg-blue-100 h-6" />
      )}

      {dataDelivery?.online_delivery && (
        <Badge weight={weight} label="online class" customClass="text-pink-700 bg-pink-100 h-6" />
      )}
      {label && (
        <Badge
          size="text-xs"
          weight={weight}
          label={label}
          customClass="text-indigo-700 bg-indigo-100 h-6"
        />
      )}
      {schedule && (
        <div className="flex items-center">
          <Icon
            height={iconSize}
            width={iconSize}
            styles="relative top-1 text-primary"
            iconName="date"
          />
          <Text size={size} weight={fontWeight} color="primary" customClass="leading-4">
            {schedule}
          </Text>
        </div>
      )}
    </div>
  )
}
export default CardSchedule
