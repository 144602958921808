import { capitalize } from "lib/helpers"

interface BadgeProps {
  label: string
  customClass?: string
  color?: string
  bgColor?: string | "bg-yellow-100"
  weight?: string
  size?: string
  padding?: string
  containerPadding?: string
}
function Badge({
  label,
  customClass = "",
  bgColor = "",
  containerPadding = "py-1 px-2",
  color = "",
  padding = "p-0",
  size = "text-[10px]",
  weight = "font-bold",
}: BadgeProps) {
  return (
    <div
      className={`${customClass} ${bgColor} ${containerPadding} flex items-center justify-center`}>
      <p className={`${weight} ${color} ${size} ${padding}`}>{capitalize(label, " ")}</p>
    </div>
  )
}
export default Badge
