import Badge from "@components/shared-components/ui/Badge"
import { capitalize, cutString } from "lib/helpers"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { useContext } from "react"
import { Tooltip } from "react-tooltip"

interface TextOverImageProps {
  index: number
  category: {
    name: string
    description: string
  }
  type?: string
  position?: "absolute" | "relative"
  paddingContainer?: "p-0" | "p-3" | "p-4" | "p-5"
}
function TextOverImage({
  index,
  category,
  type = "public",
  position = "absolute",
  paddingContainer = "p-3",
}: TextOverImageProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const badgeColor = {
    public: "bg-yellow-100 text-yellow-500",
    inhouse: "bg-blue-100 text-blue-700",
    bootcamp: "bg-pink-100 text-pink-700",
    prakerja: "bg-sky-100 text-sky-700",
  }

  let typeProgram = "In-house"
  if (type !== "inhouse") {
    typeProgram = type
  }

  return (
    <div
      className={`${position} ${paddingContainer} justify-start items-center top-0 lg:flex hidden`}>
      <a id={`${type}${index}`}>
        <Badge
          label={typeProgram}
          containerPadding="py-0 px-2"
          customClass={`${badgeColor[type]} rounded`}
        />
      </a>
      <Tooltip style={{width: "100%"}} place="top" anchorSelect={`#${type}${index}`}>
        <Text size="extra-small">
          {t(`what_is_${type.toLowerCase()}_class`)}
        </Text>
      </Tooltip>
      <a id={`category${index}`}>
        <Text
          weight="semibold"
          color="gray"
          size="2extra-small"
          customClass="ml-2 bg-gray-100 rounded px-2 capitalize">
          {cutString(capitalize(category.name, "-"), 24)}
        </Text>
      </a>
      <Tooltip style={{width: "100%"}} place="bottom" anchorSelect={`#category${index}`}>
        <Text size="extra-small">
          {category.description}
        </Text>
      </Tooltip>
    </div>
  )
}

export default TextOverImage
