import Button from "lib/components/Button"
import Image from "lib/components/Image"
import Text from "lib/components/Text"

export interface NotFoundTitleProps {
  title: string
  subtitle?: string
  image?: string
  buttonClick?(): void
  buttonName?: string
  customButton?: React.ReactNode
  width?: number
  height?: number
}

function NotFoundTitle({ title, subtitle, image = "/assets/images/empty_table.png", buttonClick, buttonName, customButton, width, height }: NotFoundTitleProps) {
  return (
    <div className="flex flex-col items-center justify-center mt-12 p-4 sm:p-0">
      <Image 
        src={image}
        alt="not-available"
        width={width || 406}
        height={height || 306}
        objectFit="cover"
        />
      <Text customClass="font-medium" alignText="center">
        {title}
      </Text>
      {subtitle &&
        <Text alignText="center">
          {subtitle}
        </Text>
      }
      {buttonName &&
        <Button customClass="mt-4" variant="contained" color="primary" onClick={buttonClick}>
          {buttonName}
        </Button>
      }
      {customButton}
    </div>
  )
}

export default NotFoundTitle
