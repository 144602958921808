import { useContext } from "react"

import CardCompany from "@components/shared-components/Card/CardCompany"
import CardSchedule from "@components/shared-components/Card/CardSchedule"
import TextOverImage from "@components/shared-components/Card/TextOverImage"
import Badge from "@components/shared-components/ui/Badge"
import { gtmEcommerce } from "lib/gtm"
import {
  capitalize,
  cutString,
  formatStringToDateFormat,
  formatToIDR,
  generateImageWebp,
  optimizeImageCloudinary,
} from "lib/helpers"
import Divider from "lib/components/Divider"
import Image from "lib/components/Image"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { Tooltip } from "react-tooltip"

export interface DefaultValueProps {
  id: string
  programName: string
  programThumbnailUrl: string
  tpProfileUrl: string
  tpName: string
  discountPrice: number
  price: number
  discount: number
  type: string
  category: {
    name: string
    description: string
    slug: string
  }
  finalRating?: number
  finalRatingParticipants?: number
  slug: string
  classCode?: string
  classEndDate?: Date
  classStartDate?: Date
  index: number
  sectionName: string
  customImage?: string
  customWidth?: string
  campaign?: string
}

interface HeaderImageProps {
  index: number
  category: {
    name: string
    description: string
  }
  programThumbnailUrl: string
  type: string
  programName: string
  customClass: string
  campaign?: string
}
export interface DetailProgramProps {
  type: string
  category: {
    name: string
    description: string
  }
  classEndDate: Date
  classStartDate: Date
  programName: string
}

export interface BadgeComponentProps {
  type: string
  category: {
    name: string
    description: string
  }
}

export function HeaderImage({
  index,
  category,
  programThumbnailUrl,
  type,
  programName,
  customClass,
}: HeaderImageProps) {
  const customImage = generateImageWebp(programThumbnailUrl)
  const thumbnailUrl = optimizeImageCloudinary(customImage, "high", "w_360")
  return (
    <div className={`relative w-full h-16 lg:h-img-card ${customClass}`}>
      <div className="h-16 w-full rounded brightness-90 overflow-hidden lg:h-full lg:rounded-t relative">
        <Image
          src={thumbnailUrl || "/assets/images/program-image.webp"}
          alt={programName}
          objectFit="cover"
          placeholder="blur"
        />
      </div>
      <TextOverImage index={index} category={category} type={type} />
    </div>
  )
}

export function Title({ programName }) {
  return (
    <div>
      <Text size="extra-small" customClass="leading-4 text-gray-800" weight="semibold">
        {cutString(programName, 58)}
      </Text>
    </div>
  )
}

export function BadgeComponent({ type, category }: BadgeComponentProps) {
  const { useTranslation: t } = useContext(LanguageContext)

  const badgeColor = {
    inhouse: "bg-blue-100 text-blue-600",
    public: "bg-green-100 text-green-600 ",
    bootcamp: "bg-pink-100 text-pink-600 ",
  }

  let typeProgram = "In-house"
  if (type !== "inhouse") {
    typeProgram = type
  }

  return (
    <div className="mb-2 flex items-center justify-start lg:hidden">
      <a id="type">
        <Badge
          label={typeProgram}
          containerPadding="py-0 px-2 lg:py-1"
          customClass={`${badgeColor[type]} rounded`}
        />
      </a>
      <Tooltip anchorSelect="#type">{t(`what_is_${type.toLowerCase()}_class`)}</Tooltip>
      <a id="category">
        <Text
          weight="semibold"
          color="gray"
          size="2extra-small"
          customClass="ml-2 bg-gray-100 rounded px-2 capitalize">
          {cutString(category.name, 30)}
        </Text>
      </a>
      <Tooltip anchorSelect="#category">{category.description}</Tooltip>
    </div>
  )
}

export function ScheduleComponent({ classStartDate, type, classEndDate }) {
  const { useTranslation: t } = useContext(LanguageContext)
  const isStartDateEndDateSame = classStartDate === classEndDate

  const startDate = {
    date: formatStringToDateFormat(classStartDate, "DD"),
    years: formatStringToDateFormat(classStartDate, "YYYY"),
    translateDay: isStartDateEndDateSame
      ? `${t(formatStringToDateFormat(classStartDate, "dddd").toLowerCase())},`
      : "",
    translateMonth: t(formatStringToDateFormat(classStartDate, "MMMM").toLowerCase()).slice(
      0,
      !isStartDateEndDateSame ? 3 : 20,
    ),
  }
  const endDate = {
    date: formatStringToDateFormat(classEndDate, "DD"),
    years: formatStringToDateFormat(classEndDate, "YYYY"),
    translateDay: isStartDateEndDateSame
      ? `${t(formatStringToDateFormat(classEndDate, "dddd").toLowerCase())},`
      : "",
    translateMonth: t(formatStringToDateFormat(classEndDate, "MMMM").toLowerCase()).slice(0, 3),
  }
  const simplifyStartDate = `${startDate.translateDay} ${startDate.date} ${startDate.translateMonth}  ${startDate.years}`
  const simplifyEndDate = `${endDate.translateDay} ${endDate.date} ${endDate.translateMonth}  ${endDate.years}`
  const scheduleType = {
    public: `${simplifyStartDate} ${!isStartDateEndDateSame ? ` - ${simplifyEndDate}` : ""}`,
    bootcamp: `${simplifyStartDate} - ${simplifyEndDate}`,
  }
  return (
    <div className="mt-2 lg:mt-0">
      {classStartDate && (
        <div>
          <CardSchedule fontWeight="medium" schedule={scheduleType?.[type]} size="extra-small" />
        </div>
      )}
    </div>
  )
}

export function DetailProgram({
  category,
  type,
  classEndDate,
  classStartDate,
  programName,
}: DetailProgramProps) {
  return (
    <div className="flex flex-col lg:py-2">
      <BadgeComponent
        category={{
          description: category.description,
          name: category.name,
        }}
        type={type}
      />
      <Title programName={programName} />
      <ScheduleComponent classEndDate={classEndDate} type={type} classStartDate={classStartDate} />
    </div>
  )
}

export function PriceComponent({ price, discount, discountPrice }) {
  return (
    <div className="row-span-1 flex flex-col justify-between py-3 lg:py-2">
      {discountPrice > 0 ? (
        <Text size="small" weight="bold" color="primary">
          {formatToIDR(discountPrice)}
        </Text>
      ) : (
        <div className="flex items-center">
          {price <= 0 || discount <= 0 ? (
            <Text size="small" weight="bold" color="primary">
              {price > 0 ? formatToIDR(price) : "FREE"}
            </Text>
          ) : (
            <Text
              customClass="leading-4 rounded bg-emerald-100 py-1 px-1 lg:px-2"
              size="2extra-small"
              color="secondary"
              weight="bold">
              FREE
            </Text>
          )}
        </div>
      )}

      <div className="flex items-center ">
        {discount > 0 && (
          <div className="mt-0.5 flex items-center justify-center space-x-3">
            <div className="rounded bg-emerald-100 px-2">
              <Text customClass="leading-4" size="2extra-small" color="secondary" weight="bold">
                {price > 0 ? `${discount} %` : "FREE"}
              </Text>
            </div>
            <Text size="2extra-small" weight="bold" color="muted" customClass="line-through">
              {price ? formatToIDR(price) : "FREE"}
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}
export function Company({ finalRating, finalRatingParticipants, tpName, tpProfileUrl }) {
  return (
    <div className="flex items-center">
      <CardCompany
        finalRating={finalRating}
        finalRatingParticipants={finalRatingParticipants}
        tpName={tpName}
        tpProfileUrl={tpProfileUrl}
      />
    </div>
  )
}
export function CardBody({ children, customClass }) {
  return <div className={`${customClass}`}>{children}</div>
}

function CardProgram({
  sectionName,
  index,
  id,
  programName,
  category,
  finalRating,
  finalRatingParticipants,
  slug,
  programThumbnailUrl,
  type,
  tpName,
  tpProfileUrl,
  discountPrice,
  discount,
  classEndDate,
  price,
  classCode,
  classStartDate,
  customImage,
}: DefaultValueProps) {
  return (
    <a
      key={id}
      href={`/${category.slug}/${type}/${slug}/${
        type !== "inhouse" && type !== "prakerja" ? classCode : ""
      }`}>
      <div
        role="button"
        tabIndex={index}
        className="mb-2 grid w-full grid-cols-3 gap-4 overflow-hidden rounded-none sm:rounded hover:cursor-pointer lg:ml-0 lg:block lg:h-card lg:border-b-0"
        onClick={async () => {
          localStorage.setItem("selectedIndexItem", (index + 1).toString())
          localStorage.setItem("sectionName", sectionName)
          await gtmEcommerce({ ecommerce: null })
          await gtmEcommerce({
            event: "select_item",
            ecommerce: {
              value: discountPrice,
              items: [
                {
                  item_list_id: sectionName.toLowerCase().replace(/ /g, "-"), // adjust to section id
                  item_list_name: sectionName, // adjust to section name
                  item_id: id, // adjust to item id
                  item_name: programName, // adjust to item name
                  item_brand: tpName, // adjust to item brand
                  item_category: capitalize(category.name, "-"), // adjust to item category
                  item_variant: `${capitalize(type)} Program`, // or Public Program, Bootcamp Program
                  index: index + 1, // adjust to the promotion index/slot
                  currency: "IDR",
                  price,
                  discount: Math.round(price * (discount / 100)),
                  quantity: 1,
                },
              ],
            },
          })
        }}>
        {/* header */}
        <HeaderImage
          category={{
            description: category.description,
            name: category.name,
          }}
          programThumbnailUrl={customImage || programThumbnailUrl}
          programName={programName}
          type={type}
          customClass="col-span-1"
          index={index}
        />
        {/* header */}

        {/* Header Title */}
        <CardBody customClass="col-span-2">
          <DetailProgram
            category={{
              description: category.description,
              name: category.name,
            }}
            classEndDate={classEndDate}
            classStartDate={classStartDate}
            programName={programName}
            type={type}
          />
          {/* Header Title */}
          <div className="hidden lg:block">
            <PriceComponent discount={discount} discountPrice={discountPrice} price={price} />
            <Company
              finalRating={finalRating}
              finalRatingParticipants={finalRatingParticipants}
              tpName={tpName}
              tpProfileUrl={tpProfileUrl}
            />
          </div>
          <div className="block lg:hidden">
            <Company
              finalRating={finalRating}
              finalRatingParticipants={finalRatingParticipants}
              tpName={tpName}
              tpProfileUrl={tpProfileUrl}
            />
            <Divider customClass="my-0 border-t-0 border-gray-300 hidden lg:block" />
            <PriceComponent discount={discount} discountPrice={discountPrice} price={price} />
          </div>

          {/* price */}
        </CardBody>

        {/* body */}
      </div>
    </a>
  )
}

export default CardProgram
