import Text from "lib/components/Text"
import RatingStart from "lib/components/Rating"
import { roundValueRating } from "lib/helpers"

interface CardCompanyProps {
  tpProfileUrl: string
  tpName: string
  finalRating: number
  finalRatingParticipants: number
  showProfile?: boolean
  isBold?: boolean
}
function CardCompany({
  tpProfileUrl,
  tpName,
  finalRating,
  finalRatingParticipants,
  showProfile = false,
  isBold = true,
}: CardCompanyProps) {
  return (
    <div className="flex flex-row items-center">
      {showProfile && (
        <div className="row-span-1 mr-2 h-[37px] w-[37px]">
          <img src={tpProfileUrl} className="h-full object-cover" alt="trainer" loading="lazy" />
        </div>
      )}

      <div className="flex flex-col justify-around">
        <Text
          customClass="leading-4 hidden lg:block relative mt-1.5"
          weight={isBold ? "bold" : "normal"}
          color="default"
          size="extra-small">
          {tpName}
        </Text>

        <Text
          size="2extra-small"
          color="gray"
          customClass="leading-4 block lg:hidden relative top-1"
          weight="medium">
          {tpName}
        </Text>
        {finalRatingParticipants > 0 && (
          <div className="mt-0.5 sm:-mt-1 flex items-center">
            <RatingStart size={14} count={1} value={roundValueRating(finalRating)} />
            <Text size="2extra-small" customClass="ml-1 leading-4" color="gray" weight="normal">
              {parseFloat(`${finalRating}`).toFixed(1)}
            </Text>
            <Text size="2extra-small" customClass="ml-1 leading-4" color="gray" weight="normal">
              {`(${finalRatingParticipants} ${
                finalRatingParticipants >= 100 ? "Ratings" : "Rating"
              }) `}
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardCompany
